import React from 'react';
import DefaultLayout from '@Layouts/Default';
import ArticleDescription, {
  ArticleDescriptionSkeleton,
} from '@components/ArticleDescription';
import Breadcrumbs from '@components/Breadcrumbs';
import { Container, Row, Col } from '@Omegapoint/components/layout';
import { getTheme } from '@styles/global';
import useStore from '@store';
import { Helmet } from 'react-helmet';
import useSWR from 'swr';
import api from '@api';
import { navigate } from 'gatsby';

const UserProductPage = ({ params, location }) => {
  const theme = getTheme(process.env.GATSBY_BRAND);
  const market = params?.culture;
  const activeMarkets = process.env.GATSBY_MARKETS.split(',');

  if (
    typeof window !== 'undefined' &&
    activeMarkets.indexOf(params?.culture) < 0
  ) {
    navigate('/');
  }

  //Plocka ut rätt slug från params
  //sortering-glasforpackningar-ofargade
  let { data, error, isLoading } = useSWR(
    { market, slug: params.slug },
    api.productFetcher
  );

  if (typeof window === 'undefined') return null;

  if (error || (!isLoading && data === 'Error: 500')) {
    return navigate('/');
  }

  return (
    <DefaultLayout
      culture={market}
      canonical={location.path}
      path={location.path}
      title=""
      description=""
    >
      <Helmet title={data?.Product?.Item?.content?.name} defer={false}>
        <meta name="robots" content="noindex" />
      </Helmet>
      {isLoading ? (
        <>
          <Container gutter="20">
            <Row gutters={theme.Variables.gutter}>
              <Col col={12} xl={7}>
                <Breadcrumbs path={location.pathname} hideSecondLast />
              </Col>
            </Row>
          </Container>
          <ArticleDescriptionSkeleton />
        </>
      ) : (
        <>
          <Helmet title={data?.Product?.Item?.content?.name} defer={false}>
            <meta
              name="description"
              content={data?.Product?.Item?.content?.description}
            />
          </Helmet>
          <Container gutter="20">
            <Row gutters={theme.Variables.gutter}>
              <Col col={12} xl={7}>
                <Breadcrumbs
                  path={location.pathname}
                  parts={data?.Product?.Breadcrumb}
                  hideSecondLast
                />
              </Col>
            </Row>
          </Container>
          <ArticleDescription
            title={data?.Product?.Item?.content?.name}
            image={{
              original:
                data?.Product?.Item?.content?.MainImage?.Formats?.WebColor,
              optimized:
                data?.Product?.Item?.content?.MainImage?.Formats
                  ?.WebColorOptimized,
            }}
            width={data?.Product?.Item?.packaging?.primary?.width}
            height={data?.Product?.Item?.packaging?.primary?.height}
            depth={data?.Product?.Item?.packaging?.primary?.depth}
            gtin={data?.Product?.Item?.packaging?.primary?.gtin}
            sku={data?.Product?.Item?.sku}
            attributes={data?.Product?.Item?.content?.attributes}
            description={data?.Product?.Item?.content?.description}
            materialDescription={data?.Product?.Item?.attributes?.material?.Key}
            surfaceDescription={data?.Product?.Item?.attributes?.surface?.Key}
            isNew={data?.Product?.IsNew === 'True'}
            additionalImages={data?.Product?.Item?.content?.images}
            unit={data?.Product?.Item?.packaging?.primary?.unit}
            unitQuantity={data?.Product?.Item?.packaging?.primary?.quantity}
          />
        </>
      )}
    </DefaultLayout>
  );
};

export default UserProductPage;
